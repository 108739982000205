<template>
  <div>
    <div class="row">
      <div class="col col-4">
        <div class=" bg-light-success rounded-xl text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            All Bookings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ allBookings }}
          </p>
        </div>
      </div>
      <div class="col col-4">
        <div class=" bg-light-success rounded-xl text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Online Bookings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ onlineBookings }}
          </p>
        </div>
      </div>
      <div class="col col-4">
        <div class=" bg-light-success rounded-xl text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Offline Bookings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ offlineBookings }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  DASHBOARD_STATS
} from "@/core/services/store/dashboard.module";

export default {
  name: "dashboard-stats",
  components: {},
  data() {
    return {
      allBookings: '0',
      onlineBookings: '0',
      offlineBookings: '0',
    };
  },
  watch: {},
  computed: {
    ...mapState({
      errors: state => state.dashboard.errors
    })
  },
  mounted() {
    this.getStats()
  },
  methods: {
    getStats() {
      this.$store.dispatch(DASHBOARD_STATS).then((res) => {
        this.allBookings = res.records.allBookings
        this.onlineBookings = res.records.onlineBookings
        this.offlineBookings = res.records.offlineBookings
      });
    },
  }
};
</script>
